import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function EditMoviecrew(props) {
  const [movieList, setMovieList] = useState([]); // For the dropdown of movie names
  const [movie_id, setMovieId] = useState(""); // To store selected movie ID
  const [crew_name, setCrewName] = useState(""); // To store the crew name
  const [designation, setDesignation] = useState(""); // To store the designation
  const [crew_image, setCrewImage] = useState(null); // To store the uploaded file
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');
  const id = localStorage.getItem('id');


  // Validation function
  const validateInputs = () => {
    const errors = {};
    if (!movie_id) {
      errors.movie_id = "Movie selection is required";
    }
    if (!crew_name) {
      errors.crew_name = "Crew name is required";
    }
    if (!designation) {
      errors.designation = "Designation is required";
    }
    if (!crew_image) {
      errors.crew_image = "Crew image is required";
    }
    return errors;
  };

  // Handle form submission
  const addCrew = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("movie_id", movie_id);
    formData.append("crew_name", crew_name);
    formData.append("designation", designation);

    if(crew_image){
      formData.append("crew_image", crew_image);

    }

    try {
      const response = await axios.put(api_url + '/updateMovieCrew/'+id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data.status) {
        alert(response.data.message);
        navigate("/Moviecrew", { replace: true });
      }
    } catch (error) {
      console.error("Error adding crew:", error);
      alert("Failed to add crew.");
    }
  };

  // Fetch movie list
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get(api_url + '/getMovies');
        setMovieList(response.data.moviedetails);
      } catch (error) {
        console.error("Error fetching movies:", error);
      }
    };
    fetchMovies();

    if (id) {
      axios.get(api_url + `/getMoviecrew/${id}`)
        .then((response) => {
          const facility = response.data.addon;
          setMovieId(facility.movie_id);
          setCrewName(facility.crew_name);
          setCrewImage(facility.crew_image);
          setDesignation(facility.designation);

        })
        .catch((error) => {
          console.error("Error fetching facility:", error);
        });
    }

  }, []);

  // Handle file input change
  const handleFileChange = (e) => {
    setCrewImage(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Add Crew</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Movie Name</p>
              <button className="itme_img11" onClick={addCrew}>Update</button>
            </div>
            <select
              className="item_input_cat"
              value={movie_id}
              onChange={(e) => setMovieId(e.target.value)}
            >
              <option value="">Select a movie</option>
              {movieList.map(movie => (
                <option key={movie.id} value={movie.id}>
                  {movie.movie_name}
                </option>
              ))}
            </select>
            {errors.movie_id && <Alert severity="error">{errors.movie_id}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Crew Name</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={crew_name}
              onChange={(e) => setCrewName(e.target.value)}
            />
            {errors.crew_name && <Alert severity="error">{errors.crew_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Designation</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
            {errors.designation && <Alert severity="error">{errors.designation}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Crew Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />
            {crew_image && <img src={crew_image} alt="Uploaded File" />}

            {errors.crew_image && <Alert severity="error">{errors.crew_image}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditMoviecrew);
