import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";

function EditMovietype(props) {
  const [movie_type, setMovieType] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!movie_type) {
      errors.movie_type = "Movie type is required";
    }
    return errors;
  }; 

  const addMovieTypeDetails = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const id = localStorage.getItem("id"); // Get the ID from the route parameters

    try {
      const response = await axios.put(`${api_url}/updatemovietype/${id}`, {
        movie_type,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status === "true") {
        alert("Movie type updated successfully");
        navigate("/MovieType", { replace: true });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error updating movie type:", error);
      alert("An error occurred while updating the movie type");
    }
  };

  useEffect(() => {
    const fetchMovieType = async () => {
      const id = localStorage.getItem("id");  // Get the ID from the route parameters

      try {
        const response = await axios.get(`${api_url}/getmovietype/${id}`);
        if (response.data.status === "true") {
          setMovieType(response.data.addon.movie_type);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching movie type:", error);
        alert("An error occurred while fetching the movie type");
      }
    };

    fetchMovieType();
  }, []);

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Edit Movie Type</p>

        
        </div>

        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Movie Type</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={movie_type}
              onChange={(e) => setMovieType(e.target.value)}
            />
            {errors.movie_type && <Alert severity="error">{errors.movie_type}</Alert>}
          </div>
        </div>

        <div className="item_details" style={{ textAlign: 'right' }}>
          <button type="button" className="itme_img11" onClick={addMovieTypeDetails}>update</button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditMovietype);
