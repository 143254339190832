// src/components/CreateEvents.js
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function EditEvents(props) {
  const [event_name, setEventName] = useState("");
  const [event_hall_id, setEventHallId] = useState("");
  const [event_image, setEventImage] = useState(null); // For file upload
  const [phoneno, setPhoneno] = useState("");
  const [email, setEmail] = useState("");
  const [event_organiser, setEventOrganiser] = useState("");
  const [hours, setHours] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState(""); // Select box for type
  const [event_date, setEventDate] = useState("");
  const [event_time, setEventTime] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Assuming you have a way to fetch event halls to populate the select box
  const [eventHalls, setEventHalls] = useState([]);

  const api_url = localStorage.getItem('api_url');
  const id = localStorage.getItem('id');


  // Fetch event halls to populate the event_hall_id select box
  useEffect(() => {
    const fetchEventHalls = async () => {
      try {
        const response = await axios.get(`${localStorage.getItem('api_url')}/getHalls`); // Adjust the endpoint as needed
        if (response.data.status === "true") {
          setEventHalls(response.data.halls);
        } else {
          console.error("Failed to fetch event halls:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching event halls:", error);
      }
    };
    fetchEventHalls();
  }, []);

  const validateInputs = () => {
    const errors = {};
    if (!event_name) {
      errors.event_name = "Event name is required";
    }
    if (!event_hall_id) {
      errors.event_hall_id = "Event hall selection is required";
    }
    if (!phoneno) {
      errors.phoneno = "Phone number is required";
    }
    if (!email) {
      errors.email = "Email is required";
    }
    if (!type) {
      errors.type = "Event type is required";
    }
    if (!event_date) {
      errors.event_date = "Event date is required";
    }
    if (!event_time) {
      errors.event_time = "Event time is required";
    }
    // Optional: Add more validations (e.g., email format, phone number length)
    return errors;
  };

  useEffect(() => {
    const fetchMovies = async () => {
      const response = await axios.get(api_url + '/getEvent/'+id);
      const facility = response.data.addon;
      setEventName(facility.event_name);
      setEventHallId(facility.event_hall_id);
      setEmail(facility.email);
      setPhoneno(facility.phoneno);
      setAbout(facility.about);
      setEventOrganiser(facility.event_organiser);
      setEventTime(facility.event_time);
      setEventImage(facility.event_image);
      setType(facility.type);
      setHours(facility.hours);
      setEventDate(facility.event_date);

    };

    fetchMovies();
  }, []);

  const addEventDetails = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("event_name", event_name);
    formData.append("event_hall_id", event_hall_id);
    if (event_image) {
      formData.append("event_image", event_image);
    }
    formData.append("phoneno", phoneno);
    formData.append("email", email);
    formData.append("event_organiser", event_organiser);
    formData.append("hours", hours);
    formData.append("about", about);
    formData.append("type", type);
    formData.append("event_date", event_date);
    formData.append("event_time", event_time);

   

      try {
        // Use PUT instead of GET to update the cast
        const response = await axios.put(api_url + '/updateEvent/' + id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
    
        if (response.data.status) {
          alert(response.data.message);
          navigate("/Events", { replace: true });
        }
      } catch (error) {
        console.error("Error updating event:", error);
        alert("Failed to update event.");
      }


  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Add Event and Sports</p>
        </div>
        <hr />
        <div className="product_detail">
          {/* Event Name */}
          <div className="item_details">
            <p className="item_name">Event Name</p>
            <input
              type="text"
              className="item_input_cat"
              value={event_name}
              onChange={(e) => setEventName(e.target.value)}
            />
            {errors.event_name && <Alert severity="error">{errors.event_name}</Alert>}
          </div>

          {/* Event Hall */}
          <div className="item_details">
            <p className="item_name">Event Hall</p>
            <select
              className="item_input_cat"
              value={event_hall_id}
              onChange={(e) => setEventHallId(e.target.value)}
            >
              <option value="">Select Event Hall</option>
              {eventHalls.map((hall) => (
                <option key={hall.id} value={hall.id}>
                  {hall.hall_name}
                </option>
              ))}
            </select>
            {errors.event_hall_id && <Alert severity="error">{errors.event_hall_id}</Alert>}
          </div>

          {/* Event Image */}
          <div className="item_details">
            <p className="item_name">Event Image</p>
            <input
              type="file"
              className="item_input_cat"
              accept="image/*"
              onChange={(e) => setEventImage(e.target.files[0])}
            />
                        {event_image && <img src={event_image} alt="Uploaded File" />}

          </div>

          {/* Phone Number */}
          <div className="item_details">
            <p className="item_name">Phone Number</p>
            <input
              type="text"
              className="item_input_cat"
              value={phoneno}
              onChange={(e) => setPhoneno(e.target.value)}
            />
            {errors.phoneno && <Alert severity="error">{errors.phoneno}</Alert>}
          </div>

          {/* Email */}
          <div className="item_details">
            <p className="item_name">Email</p>
            <input
              type="email"
              className="item_input_cat"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <Alert severity="error">{errors.email}</Alert>}
          </div>

          {/* Event Organiser */}
          <div className="item_details">
            <p className="item_name">Event Organiser</p>
            <input
              type="text"
              className="item_input_cat"
              value={event_organiser}
              onChange={(e) => setEventOrganiser(e.target.value)}
            />
          </div>

          {/* Hours */}
          <div className="item_details">
            <p className="item_name">Hours</p>
            <input
              type="text"
              className="item_input_cat"
              value={hours}
              onChange={(e) => setHours(e.target.value)}
            />
          </div>

          {/* About */}
          <div className="item_details">
            <p className="item_name">About</p>
            <textarea
              className="item_input_cat"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              rows="4"
            ></textarea>
          </div>

          {/* Event Type */}
          <div className="item_details">
            <p className="item_name">Event Type</p>
            <select
              className="item_input_cat"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Select Event Type</option>
              <option value="event">Event</option>
              <option value="sport">Sport</option>
            </select>
            {errors.type && <Alert severity="error">{errors.type}</Alert>}
          </div>

          {/* Event Date */}
          <div className="item_details">
            <p className="item_name">Event Date</p>
            <input
              type="date"
              className="item_input_cat"
              value={event_date}
              onChange={(e) => setEventDate(e.target.value)}
            />
            {errors.event_date && <Alert severity="error">{errors.event_date}</Alert>}
          </div>

          {/* Event Time */}
          <div className="item_details">
            <p className="item_name">Event Time</p>
            <input
              type="time"
              className="item_input_cat"
              value={event_time}
              onChange={(e) => setEventTime(e.target.value)}
            />
            {errors.event_time && <Alert severity="error">{errors.event_time}</Alert>}
          </div>

          {/* Save Button */}
          <button className="itme_img11" onClick={addEventDetails}>Update</button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditEvents);
