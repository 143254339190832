import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function EditMoviecast(props) {
  const [movieList, setMovieList] = useState([]); // For the dropdown of movie names
  const [movie_id, setMovieId] = useState(""); // To store selected movie ID
  const [cast_name, setCastName] = useState(""); // To store the cast name
  const [cast_image, setCastImage] = useState(null); // To store the uploaded file
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');
  const id = localStorage.getItem('id');


  // Fetch movie list on component mount
  useEffect(() => {
    const fetchMovies = async () => {
      const response = await axios.get(api_url + '/getmovies');
      setMovieList(response.data.moviedetails); // Assuming your backend sends a 'movies' array
    };

    if (id) {
      axios.get(api_url + `/getMoviecast/${id}`)
        .then((response) => {
          const facility = response.data.addon;
          setMovieId(facility.movie_id);
          setCastName(facility.cast_name);
          setCastImage(facility.cast_image);

        })
        .catch((error) => {
          console.error("Error fetching facility:", error);
        });
    }
    fetchMovies();
  }, []);

  // Validation function
  const validateInputs = () => {
    const errors = {};
    if (!movie_id) {
      errors.movie_id = "Movie selection is required";
    }
    if (!cast_name) {
      errors.cast_name = "Cast name is required";
    }
    if (!cast_image) {
      errors.cast_image = "Cast image is required";
    }
    return errors;
  };

  // Handle form submission
  // const addCast = async () => {
  //   const validationErrors = validateInputs();
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return;
  //   }

  //   const formData = new FormData();

  //   formData.append("movie_id", movie_id);
  //   formData.append("cast_name", cast_name);

  //   if(cast_image){
  //     formData.append("cast_image", cast_image);
  //   }

  //   try {
  //     const response = await axios.get(api_url + '/updateMovieCast/'+id, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
  //     if (response.data.status) {
  //       alert(response.data.message);
  //       navigate("/Moviecast", { replace: true });
  //     }
  //   } catch (error) {
  //     console.error("Error adding cast:", error);
  //     alert("Failed to update cast.");
  //   }
  // };

  const addCast = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    const formData = new FormData();
    formData.append("movie_id", movie_id);
    formData.append("cast_name", cast_name);
  
    if (cast_image) {
      formData.append("cast_image", cast_image);
    }
  
    try {
      // Use PUT instead of GET to update the cast
      const response = await axios.put(api_url + '/updateMovieCast/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.status) {
        alert(response.data.message);
        navigate("/Moviecast", { replace: true });
      }
    } catch (error) {
      console.error("Error updating cast:", error);
      alert("Failed to update cast.");
    }
  };
  

  // Handle file input change
  const handleFileChange = (e) => {
    setCastImage(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Add Cast</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr />
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Movie Name</p>
              <button className="itme_img11" onClick={addCast}>Update</button>
            </div>
            <select
              className="item_input_cat"
              value={movie_id}
              onChange={(e) => setMovieId(e.target.value)}
            >
              <option value="">Select a movie</option>
              {movieList.map(movie => (
                <option key={movie.id} value={movie.id}>
                  {movie.movie_name}
                </option>
              ))}
            </select>
            {errors.movie_id && <Alert severity="error">{errors.movie_id}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Cast Name</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={cast_name}
              onChange={(e) => setCastName(e.target.value)}
            />
            {errors.cast_name && <Alert severity="error">{errors.cast_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Cast Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />
            {cast_image && <img src={cast_image} alt="Uploaded File" />}

            {errors.cast_image && <Alert severity="error">{errors.cast_image}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditMoviecast);
